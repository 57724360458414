.popup{
display: flex;
align-items: center;
justify-content: center;
flex-direction: row;
}

.CookieConsent {
  
    max-width: 500px;
  border-radius: 5px; 
  padding: 20px 30px;
  border-color: white;
  border-style: solid;
  background-color:  #1684ac;
  color: white;
  position:fixed;

  top: 45%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate3d(-50%, -50%,9999990px);
  width: 95%;
  box-shadow: 0 3px 10px rgba(0,0,0,0.1);
}
.CookieConsent a{
color: white;

font-weight: bold;
}

.overlayclass { 
    position:fixed;
    background-color:#1684ac;
    top: 0%;
    left: 0;
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 500px) {

.popup{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    }
    
    .CookieConsent {
      
        max-width: 400px;
      border-radius: 5px; 
      padding: 20px 30px;
      border-color: white;
      border-style: solid;
      background-color:  #1684ac;
      color: white;
      position:fixed;
    
      top:60%;
      left: 50%;
      right: auto;
      bottom: auto;
      transform: translate3d(-50%, -50%,9999990px);
      width: 70%;
      box-shadow: 0 3px 10px rgba(0,0,0,0.1);
    }
    .CookieConsent a{
    color: white;
    
    font-weight: bold;
    }
    
    .overlayclass { 
        position:fixed;
        background-color:#1684ac;
        top: 0%;
        left: 0;
        width: 100%;
        height: 100%;
    }
}