.nav {
  margin-top: 20px;
  color: #56aee0;
  font-size: 20px;
}

.nav:hover {
  color: black;
  font-weight: 600;
}

.logo-my {
 
  margin-left: 10px;
}

@keyframes barbeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.bar {
  color: black;
  cursor: pointer;
  width: 45px;
  height: 45px;
  
  left: 10px; /* Adjust the positioning as needed */
}
.menu{
margin-top: 27px;
}
.bar:hover {
  animation: barbeat 1s infinite;
  fill: #1684ac;
  color: #000000;
  opacity: 1;
  transition: opacity 500ms;
}

@keyframes close {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  100% {
    transform: scale(1);
  }
}

.close {
  color: black;
  cursor: pointer;
  width: 45px;
  height: 45px;
  top: 20px;
  left: 10px; /* Adjust the positioning as needed */
}

.close:hover {
  animation: barbeat 1s infinite;
  fill: #1684ac;
  color: #000000;
  opacity: 1;
  transition: opacity 500ms;
}

/* Mobile menu styles */
.menu-container {
  position: fixed;
  top: 0;
  left: -300px; /* Initial position outside the viewport */
  width: 300px;
  height: 100vh;
  background-color: #ffffff;
  transition: left 0.3s ease-in-out;
}

.menu-open {
  left: 0; /* Slide into the viewport */
}

.menu-content {
  padding: 10px;

}
  