.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: center;
  min-height: 100vh;
}

.account {
  align-items: center;
  display: flex;
  height: 45px;
  justify-content: center;
  left: -10px;
  position: absolute;
  top: -10px;
  width: 45px;
}

.body-post {
  margin: 20px;
}

.body-post::first-letter {
  color: black;
  font-size: 150%;
}

.button-center {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.card-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.catBox {
  align-items: center;
  background-color: beige;
  display: flex;
  flex-direction: row;
  justify-content: left;
}
.comment-count{
display: flex;

margin-top: 5px;
justify-content: center;
align-items: center;
width: 30px;
height: 30px;
border-radius: 10%;
background-color:#1684ac;
color:white;
font-size: 20px;
font-weight: bold;
}
.comment-bottom {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-top: 10px;
}

.comment-bottom-fullpage {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-top: 10px;
}

.comment-comment {
  display: flex;
  gap: 2%;
}

.comment-icons {
  margin-top: 10px;
}

.comment-mail-and-name {
  font-weight: bold;
}

.comment-mail-and-name a {
  color: black;
}

.comment-text {
  padding-top: 2px;
}

.container-post {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 10px;
}

.content-1 {
  margin: auto;
  padding: 10px;
  width: 100%;
}

.counter-like {
  font-size: 25px;
  font-weight: bold;
}

.del {
  color: red;
}

.edit {
  
  border-radius: 50%;
  color: white;
  display: flex;
  font-size: 12px;
  font-weight: bold;
  height: 35px;

  
  justify-content: center;
  position: absolute;
  right: -10px;
  top: -10px;
  width: 35px;
}
.footer-links{
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
gap: 10px
}
.login-button{
display: flex;
justify-content: center;
margin-top: 10px;
}
.huvud {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
.headComment{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.img-front {
  border-radius: 10px;
}
.img-front-full-post{
  display: flex;
  justify-content: center;
  border-radius: 10px;
  align-self: center;
}
.img-front-full {
  display: flex;
  justify-content: center;
  border-radius: 10px;
}

.img-front-full img {
  height: auto;
  max-width: 100%;
  object-fit: contain;
}

.kategori {
  font-size: 20px;
  text-align: center;
}

.like-count {
  font-size: 19px;
  font-weight: bold;
}

.likunlike {
  font-size: 18px;
  font-weight: bold;
}

.lankar {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
.page-numbering{
display: flex;
justify-content: center;
font-size: larger;
font-weight: bold;
}
.pageHeader {
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.padding-post {
  padding-bottom: 20px;
}

.panel-text {
  color: #1684ac;
  font-size: large;
  font-weight: bold;
}

.panel-text a:hover {
  color: black;
  font-size: large;
  font-weight: bold;
}

.post-top {
  display: flex;
  display: row;
  gap: 50%;
  margin-top: 5px;
}

.post-top-all {
  display: flex;
  flex-direction: row;
  gap: 75%;
  margin-left: 20px;
}

.profil {
  align-self: center;
  display: flex;
  justify-content: center;
}

.profil-fulpage {
  border-radius: 50%;
  height: 250px;
  width: 250px;
}

.profil-img {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.profil-info {
  align-items: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.side-missing {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 42px;
}

.size-input {
  padding: 10px;
}

.social {
  display: flex;
  flex-direction: row;
  gap: 10%;
}

.socialIcons {
  cursor: pointer;
  margin-top: 5px;
  width: 40px;
}

.socialIcons-front {
  cursor: pointer;
  margin-top: 5px;
  width: 30px;
}

.sort-this {
  /* Add the class name "sort-this" to the parent container to apply alphabetical sorting */
}

.space {
  margin-top: 10px;
}

.space-drone {
  margin-left: 10px;
}

.space-img {
  margin-top: 10px;
}

.space-img button {
  display: flex;
  margin-top: 10px;
}

.space-profil {
  padding: 10px;
  text-align: center;
}

.table-container {
  overflow-x: auto;
}

.text-center {
  /* Add the class name "text-center" to center align the text */
}

.top-page-search {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 20px;
}

.user-img {
  border-radius: 50%;
  width: 10%;
}
.logout-modal{
  display: flex;
align-items: center;
justify-content: center;
flex-direction: row;

}
.logout-content{
max-width: 500px;
  border-radius: 5px; 
  padding: 20px 30px;
  border-color: black;
  border-style: solid;
  background-color:  #1684ac;
  color: white;
  position:fixed;

  top: 45%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate3d(-50%, -50%,9999990px);
  width: 95%;
  box-shadow: 0 3px 10px rgba(0,0,0,0.1);
}
.modal-buttons{
  display: flex;
  flex-direction: row;
  gap:10px;
  margin-top: 10px;
  justify-content: center;

}
@media only screen and (max-width: 500px) {
  .column.is-full-mobile,
  .column.is-half-desktop,
  .column.is-half-tablet {
    width: 100%;
  }

  .comment-bottom {
    align-items: center;
    display: flex;
  }

  .comment-bottom-fullpage {
    align-items: center;
    display: flex;
    margin-top: 10px;
  }

  .columns.is-multiline {
    flex-direction: column;
  }

  .custom-card {
    padding: 2%;
  }

  .custom-card table {
    width: 100%;
  }
  .footer-links{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px
    }
  .custom-card table td {
    display: block;
  }

  .custom-card table td:first-child {
    margin-bottom: 5px;
  }

  .g-recaptcha {
    transform: scale(0.77);
    transform-origin: 0 0;
  }

  .post-top {
    display: flex;
    flex-direction: row;
    gap: 15%;
  }

  .post-top-all {
    gap: 10%;
    display: flex;
    flex-direction:row;
  }

  .role-thumbnail img {
    height: auto;
    max-width: 40%;
  }

  .subtitle.is-5,
  .button {
    font-size: 14px;
  }
  

  .table-profil {
    display: flex;
    flex-direction: column;
  }

  .title {
    font-size: 22px;
  }

  .title-mobil {
    margin-bottom: 5px;
  }

  .top-page-search {
    display: flex;
    flex-direction: column;
    gap: 15%;
    margin-bottom: 10px;
  }
}
